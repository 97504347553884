import {default as React} from "react"
import {useStaticQuery, graphql} from "gatsby"
import ArticleTile from "~/components/resources/articles/tile"
import Sidebar from "~/components/articles/sidebar"

const ResourceArticles = () => {
  const { allWpPost: { nodes: posts } } = useStaticQuery(graphql`
    {
      allWpPost(limit: 4, sort: { fields: date, order: DESC }){
        nodes {
          databaseId
          title
          slug
          date(formatString: "dddd, MMM D, YYYY")
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100, aspectRatio:1)
                }
              }
            }
          }
          categories {
            nodes {
              databaseId
              slug
              name
            }
          }
        }
      }
    }
  `)

  return (
    <section className="py-24">
      <div className="container mx-auto px-8">
        <div className="flex flex-wrap -mx-8 mb-12">
          <div className="w-full md:w-3/4 px-8">
            <h1 className="text-primary text-6xl">Recent Articles</h1>
          </div>
        </div>
        <div className="flex flex-wrap -mx-8">
          <div className="flex flex-wrap w-full md:w-2/3">
            { posts.map(post => (
              <div
                key={post.databaseId}
                className="w-full md:w-1/2 px-8 mb-8">
                <ArticleTile
                  post={post} />
              </div>
            ))}
          </div>
          <div className="relative w-full md:w-1/3 px-8">
            <Sidebar /> 
          </div>
        </div>
      </div>
    </section>
  )
}

export default ResourceArticles