/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import Layout from "~/components/layout"
import Seo from "~/components/seo"
import DotsIcon from "~/images/dots.inline.svg"
import QuickResources from "~/components/resources/quickResources"
import ResourceArticles from "~/components/resources/articles"
import Callout from "~/components/callout"
import Faqs from "~/components/faqs"

const ResourceCenterPage = ({ data: { page } }) => {
  return (
    <Layout>
      <Seo title={page.title} />
      <section
        sx={{
          backgroundImage: `url('${page.featuredImage?.node?.localFile?.publicURL}')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          borderBottomRightRadius: '50% 10%'
        }}
        className="relative pt-44 bg-primary overflow-hidden">
        <div className="container mx-auto px-8">
          <div className="flex flex-wrap -mx-8">
            <div className="flex w-full px-8">
              <h1
                className="text-5xl md:text-7xl text-white mb-8 md:mb-20">{page.title}
              </h1>
            </div>
          </div>
          <div className="flex flex-wrap -mx-8">
            <div className="flex flex-col justify-end w-full md:w-1/3 px-8">
              <small className="text-secondary mb-10">When it Matters</small>
            </div>
            <div className="flex items-end w-full md:w-2/3 px-8">
              <div
                className="relative w-full bg-secondary bg-opacity-80 pt-8 pb-6 px-12">
                <DotsIcon
                  className="absolute top-0 left-0 -mt-8 -ml-8 w-32 opacity-40" />
                <div
                  className="text-white"
                  dangerouslySetInnerHTML={{
                    __html: page.content
                  }} />
              </div>
            </div>
          </div>
        </div>
      </section>
      { page.resources?.quickResources && (
        <QuickResources
          resources={page.resources?.quickResources} />
      )}
      {/** Recent Articles Section */}
        <ResourceArticles />
      {/** Callout Section */}
      { page.callout && (
        <Callout
          callout={page.callout} />
      )}
      { page.resources?.relatedFaqs && (
        <Faqs
          faqs={page.resources.relatedFaqs} />
      )}
    </Layout>
  )
}

export const query = graphql`
  query ResourcesPageQuery($databaseId: Int!){
    page: wpPage(databaseId: { eq: $databaseId}) {
      databaseId
      title
      content
      featuredImage {
        node {
          localFile {
            publicURL
          }
        }
      }
      resources {
        quickResources {
          slug
          title
          description
        }
        relatedFaqs{
          ...on WpFaq {
            databaseId
            title
            content
            faqsCategories {
              nodes{
                termTaxonomyId
                name
              }
            }
          }
        }
      }
      callout {
        title
        subtitle
        description
        image {
          file {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
              }
            }
          }
          position
        }
        button {
          text
          url
        }
      }
    }
  }
`

export default ResourceCenterPage