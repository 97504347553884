/** @jsx jsx */
import {jsx} from "theme-ui"
import {useState} from "react"
import Accordion from "~/components/resources/accordion"

const QuickResources = ({ resources }) => {
  const [active, setActive] = useState([])
  return (
    <section className="pt-24">
      <div className="container mx-auto px-8">
        <div
          className="flex -mx-8 mb-12">
            <div
              className="w-full px-8">
              <h6 className="text-secondary mb-4">Your Questions Answered</h6>
              <h1 className="text-6xl">Quick Resources</h1>
            </div>
        </div>
        <div className="flex flex-wrap items-start -mx-8">
          { resources.map(resource => {
            const isActive = !!active.find(({ slug }) => slug === resource.slug)
            return (
              <div
                key={resource.slug}
                className="flex w-full md:w-1/2 px-8">
                <Accordion
                  resource={resource}
                  isActive={isActive}
                  toggleUp={() => {
                    const index = active.findIndex(({ slug }) => slug === resource.slug)
                    const copied = [...active]
                    copied.splice(index, 1)
                    setActive(copied)
                  }}
                  toggleDown={() => {
                    setActive([...active, resource])
                  }} />
              </div>
            )
          })} 
        </div>
      </div>
    </section>
  )
}

export default QuickResources 